<template>
  <div>
    <px-capa :banner-capa="header" class="white--text">
      <template v-slot:title>
        Compre ferramentas para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        :show-price="false"
        link="/fornecedor/23742"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import gridMixin from '@/mixins/grid-mixin';
import RdDuvidaFormSubscription from '../../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from '../LandingPageWhatsapp.vue';
import header from '../../../assets/landing-page/pumatek/banner desk.png';
import puncaoTrocaRapida from '../../../assets/landing-page/pumatek/PUNÇÃO DE TROCA RÁPIDA COM EJETOR - desk.png';
import puncaoTrocaRapidaMobile from '../../../assets/landing-page/pumatek/PUNÇÃO DE TROCA RÁPIDA COM EJETOR -mobile.png';

import puncaoCilindrica from '../../../assets/landing-page/pumatek/PUNÇÃO DE CABEÇA CILINDRICA - DESK.png';
import puncaoCilindricaMobile from '../../../assets/landing-page/pumatek/PUNÇÃO DE CABEÇA CILINDRICA -mobile.png';

import matriz from '../../../assets/landing-page/pumatek/MATRIZ CILINDRICA - DESK.png';
import matrizMobile from '../../../assets/landing-page/pumatek/MATRIZ CILINDRICA - MOBILE.png';

import puncaoRapida from '../../../assets/landing-page/pumatek/porta punção -desk.png';
import puncaoRapidaMobile from '../../../assets/landing-page/pumatek/porta punção - mobile.png';


export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and fornecedor.id = 23742 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(puncaoTrocaRapida, puncaoTrocaRapidaMobile),
        link: '/oferta/144279',
      },
      {
        foto: this.getGridImg(puncaoCilindrica, puncaoCilindricaMobile),
        link: '/oferta/144277',
      },
      {
        foto: this.getGridImg(matriz, matrizMobile),
        link: '/oferta/144287',
      },
      {
        foto: this.getGridImg(puncaoRapida, puncaoRapidaMobile),
        link: '/oferta/144300',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
